import React, { Component } from 'react';
import {
  Grid,
  Segment,
  Image,
  Header,
  Modal,
  Divider,
} from 'semantic-ui-react';

import ImageTile from "../components/ImageTile.js";
import ImageCarousel from "../components/ImageCarousel.js";

import drive1 from "../img/pics/driveway_before.jpg";
import drive2 from "../img/pics/driveway_after.jpg";
import drive3 from "../img/pics/driveway2_split.jpg";
import drive4 from "../img/pics/driveway3_split.jpg";
import side1 from "../img/pics/sidewalk_before.jpg";
import side2 from "../img/pics/sidewalk_after.jpg";
import fence1 from "../img/pics/fence_before.jpg";
import fence2 from "../img/pics/fence_after.jpg";
import deck1 from "../img/pics/deck_before.jpg";
import deck2 from "../img/pics/deck_after.jpg";
import patio3 from "../img/pics/patio_before.jpg";
import patio4 from "../img/pics/patio_after.jpg";
import dock1 from "../img/pics/dock_before.jpg";
import dock2 from "../img/pics/dock_after.jpg";
import house1 from "../img/pics/house_before.jpg";
import house2 from "../img/pics/house_after.jpg";
import house3 from "../img/pics/house_split.jpg";
import house4 from "../img/pics/house2_split.jpg";
import gutter1 from "../img/pics/gutter_split.jpg";
import gutter2 from "../img/pics/gutter2_split.jpg";

const slides = [
  [drive1, drive2],
  [side1, side2],
  [fence1, fence2],
  [patio3, patio4],
  [deck1, deck2],
  [dock1, dock2],
  [house1, house2],
];

const cards = {
  Driveways: [
    {
      hide: drive1,
      show: drive2,
      alt: 'driveway',
    },
    {
      hide: null,
      show: drive3,
      alt: 'driveway 2',
    },
    {
      hide: null,
      show: drive4,
      alt: 'driveway 3',
    },
  ],
  Sidewalks: [
    {
      hide: side1,
      show: side2,
      alt: 'sidewalk',
    },
  ],
  Fences: [
    {
      hide: fence1,
      show: fence2,
      alt: 'fence',
    },
  ],
  Patios: [
    {
      hide: patio3,
      show: patio4,
      alt: 'patio',
    },
  ],
  Decks: [
    {
      hide: deck1,
      show: deck2,
      alt: 'patio',
    },
  ],
  Docks: [
    {
      hide: dock1,
      show: dock2,
      alt: 'dock',
    },
  ],
  Houses: [
    {
      hide: house1,
      show: house2,
      alt: 'house',
    },
    {
      hide: null,
      show: house3,
      alt: 'house 2',
    },
    {
      hide: null,
      show: house4,
      alt: 'house 3',
    },
  ],
  Gutters: [
    {
      hide: null,
      show: gutter1,
      alt: 'gutter',
    },
    {
      hide: null,
      show: gutter2,
      alt: 'gutter2',
    },
  ]
};

class Gallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalImages: [],
      modalAlt: '',
      modalOpen: false,
    }
  }

  enlarge = (images, alt) => {
    this.setState({
      modalOpen: true,
      modalImages: images,
      modalAlt: alt,
    });
  }

  handleClose = () => {
    this.setState({ modalOpen: false });
  }

  genModal =() => {
    const { modalImages, modalAlt } = this.state;

    return(
      <Segment basic>
        {
          modalImages.map((image, index) => {
            if (image) {
              return (
                <Image key={index} src={image} alt={'CAM Pressure Washing Lakeland ' + modalAlt} size='huge' centered rounded bordered />
              )
            }
          })
        }
      </Segment>
    )
  }

  genCards = () => {
    return (
      <div>
        {
          Object.keys(cards).map(header => {
            return (
              <div key={header}>
                <Header key={header + 'Header'} textAlign='center' size='huge'>{header}</Header>
                <Grid
                  container
                  columns='3'
                  stackable
                  centered
                  doubling
                  key={header + 'Grid'}
                >
                  {
                    cards[header].map(card => {
                      return (
                        <Grid.Column key={card.alt}>
                          <ImageTile hide={card.hide} show={card.show} alt={card.alt} enlarge={() => this.enlarge([card.hide, card.show], card.alt)} />
                        </Grid.Column>
                      )
                    })
                  }
                </Grid>
                <Divider inverted horizontal />
              </div>
            )
          })
        }
      </div>
    )
  }

  componentDidMount() {
    document.title = 'Gallery | CAM Pressure Washing | Lakeland, FL';
  }

  render() {
    const { modalOpen } = this.state;
    return (
      <div>
        <Header as='h1' color='blue' textAlign='center'>Gallery</Header>
        <Segment>
          <ImageCarousel slides={slides} width={2800} height={1400} />
        </Segment>
        <br />
        {this.genCards()}
        

        <Modal
          open={modalOpen}
          onClose={this.handleClose}
          // basic
          centered
          closeIcon='close'
          // closeOnDimmerClick={false}
        >
          <Modal.Content>
            {this.genModal()}
          </Modal.Content>
        </Modal>
      </div>
    )
  }
}

export default Gallery;