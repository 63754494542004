import React, { Component } from 'react';
import {
  Header,
  Grid,
  Container,
  Button,
  Image,
  Card,
  Icon,
} from 'semantic-ui-react';
import portrait from '../img/portrait-v2.jpg';

import phoneCall from '../convert';


class Contact extends Component {

  componentDidMount() {
    document.title = 'Contact Us | CAM Pressure Washing | Lakeland, FL';
  }

  render() {
    return (
      <div>
        <Header as='h1' color='blue' textAlign='center'>Contact Us</Header>
        <Grid>
          <Grid.Column>
            <Grid.Row textAlign='center'>
              <Container>
                <Card centered color='blue' raised style={{ fontSize: '1.1rem' }}>
                  <Image src={portrait} wrapped ui={false} alt='CAM Pressure Washing Portrait' />
                  <Card.Content>
                    <Card.Header>Cam Dahl</Card.Header>
                    <Card.Meta>Owner</Card.Meta>
                    <Card.Description>
                      Pressure Washing Professional based out of Lakeland, FL.
                    </Card.Description>
                  </Card.Content>
                  <Card.Content>
                    <Card.Description>
                      <a href='mailto:campressurewashing@gmail.com'><Icon name='mail' /> campressurewashing@gmail.com</a>
                    </Card.Description>
                    <Card.Description>
                      <a href="tel:+18634091471" onClick={phoneCall}><Icon name='phone' /> 863-409-1471</a>
                    </Card.Description>
                  </Card.Content>
                  <Card.Content extra>
                    <Button as='a' href="tel:+18634091471" onClick={phoneCall} primary icon labelPosition='left' fluid>
                      <Icon name='phone' />
                      Call Now<br />863-409-1471
                    </Button>
                  </Card.Content>
                </Card>
              </Container>
            </Grid.Row>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

export default Contact;