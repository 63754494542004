import React, { Component } from 'react';
import { CarouselProvider, Slide, Slider, ButtonNext, ButtonBack } from "pure-react-carousel";
import { Grid, Image, Icon } from "semantic-ui-react";

import "pure-react-carousel/dist/react-carousel.es.css";

import CustomDotGroup from "./CustomDotGroup.js";

class ImageCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slides: this.props.slides || [],
      width: this.props.width || 1920,
      height: this.props.height || 600,
    }
  }

  generateSlides = () => {
    const { slides } = this.state;
    let slideTags = [];

    // console.log(slides);
    
    slides.forEach((images, index) => {
      let imageTag = [];
      if (Array.isArray(images)) {
        images.forEach((image, ind) => {
          imageTag.push(
            <Grid.Column key={'col' + ind}>
              <Image key={'img' + ind} src={image} alt={'CAM Pressure Washing Lakeland ' + ind} rounded bordered />
            </Grid.Column>
          );
        })
      }
      else {
        imageTag = <Grid.Column key={'col0'}>
          <Image src={images} alt='CAM Pressure Washing Lakeland' centered rounded bordered />
        </Grid.Column>;
      }
      // console.log(imageTag);
      slideTags.push(
        <Slide key={index} tag="a" index={index}>
          <Grid columns='equal' textAlign='center'>
            {imageTag}
          </Grid>
        </Slide>
      );
    })
    return slideTags;
  }

  render() {
    const { height, width, slides } = this.state;

    let length = slides.length;
    let playing = (length > 1);
    let dots = (playing) ? <CustomDotGroup slides={length} size='medium' /> : '';

    console.log(height);
    console.log(height/2);

    return (
      <CarouselProvider
        naturalSlideWidth={width}
        naturalSlideHeight={height}
        totalSlides={length}
        isPlaying={playing}
        touchEnabled={playing}
        dragEnabled={playing}
        interval="8000"
      >
        <div style={{ position: "relative" }}>
          <Slider>{this.generateSlides()}</Slider>
          <ButtonBack
            className="carousel-back"
            style={{
              position: "absolute",
              left: "0",
              top: "50%",
              transform: "translate(-75%, -50%)",
            }}
          >
            <Icon name="angle left" size="huge" style={{ color: "white" }} />
          </ButtonBack>
          <ButtonNext
            className="carousel-next"
            style={{
              position: "absolute",
              right: "0",
              top: "50%",
              transform: "translate(75%, -50%)",
            }}
          >
            <Icon name="angle right" size="huge" style={{ color: "white" }} />
          </ButtonNext>
        </div>
        {/* {dots} */}
      </CarouselProvider>
    );
  }
}

export default ImageCarousel;