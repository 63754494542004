import React, { Component } from 'react';
import { Link } from "react-router-dom";
import {
  Button,
  Grid,
  Icon,
  Segment,
  Container,
  Header,
  Image,
} from 'semantic-ui-react';

import ImageCarousel from "../components/ImageCarousel.js";
import trailer from "../img/TrailerPic.jpg";

import phoneCall from '../convert';

class HomepageLayout extends Component {

  componentDidMount() {
    document.title = 'CAM Pressure Washing | Lakeland, FL | (863) 409-1471';
  }

  render(){
    return (
      <div style={{ padding: '1em 0em' }}
      >
        <Header as='h1' color='blue' textAlign='center'>CAM Pressure Washing</Header>
        <Header as='h2' color='blue' textAlign='center'>Lakeland, FL</Header>
        <Grid textAlign='center'>
          <Grid.Column>
            <Container>
              <Image src={trailer} rounded centered bordered size='huge' />
            </Container>
            <br />
            <Link to="/services">
              <Button primary size='huge'>
                Services
              <Icon name='right arrow' />
              </Button>
            </Link>
            <Link to="/gallery">
              <Button primary size='huge'>
                Gallery
              <Icon name='right arrow' />
              </Button>
            </Link>
            <br />
            <br />
            <Button as='a' href="tel:+18634091471" onClick={phoneCall} primary icon labelPosition='left'>
              <Icon name='phone' />
              Call Now
              <br />
              863-409-1471
            </Button>
          </Grid.Column>
        </Grid>
      </div>
    )
  }
}

export default HomepageLayout;