import React, { Component } from 'react';
import {
  Grid,
  Container,
  Button,
  Image,
  Card,
  Icon,
  Header,
} from 'semantic-ui-react';
import driveway from '../img/pics/driveway_split.jpg';
import sidewalk from '../img/pics/sidewalk_split.jpg';
import fence from '../img/pics/fence_after.jpg';
import patio from '../img/pics/patio_split.jpg';
import deck from '../img/pics/deck_split.jpg';
import dock from '../img/pics/dock_split.jpg';
import house from '../img/pics/house3_split.jpg';
import gutter from '../img/pics/gutter_split.jpg';

import phoneCall from '../convert';

class Services extends Component {

  componentDidMount() {
    document.title = 'Pressure Washing Services | CAM Pressure Washing | Lakeland, FL';
  }

  render() {
    let callBtn = <Button as='a' href="tel:+18634091471" onClick={phoneCall} primary icon labelPosition='left' fluid>
      <Icon name='phone' />
      Call for Quote<br />863-409-1471
    </Button>
    return (
      <div>
        <Header as="h1" color="blue" textAlign="center">
          Pressure Washing Services
        </Header>
        <Grid>
          <Grid.Column>
            <Grid.Row textAlign="center">
              <Card.Group>
                <Card centered color="blue" raised>
                  <Image
                    as="a"
                    href="/gallery"
                    src={driveway}
                    wrapped
                    ui={false}
                    alt="CAM Pressure Washing Lakeland Driveway"
                  />
                  <Card.Content>
                    <Card.Header>Driveway Cleaning</Card.Header>
                    <Card.Meta>Concrete, Brick, Stone, etc.</Card.Meta>
                    <Card.Description>
                      <Header>Call for Pricing</Header>
                    </Card.Description>
                  </Card.Content>
                  <Card.Content extra>{callBtn}</Card.Content>
                </Card>
                <Card centered color="blue" raised>
                  <Image
                    as="a"
                    href="/gallery"
                    src={sidewalk}
                    wrapped
                    ui={false}
                    alt="CAM Pressure Washing Lakeland Sidewalk"
                  />
                  <Card.Content>
                    <Card.Header>Sidewalk Cleaning</Card.Header>
                    <Card.Meta>Concrete, Brick, Stone, etc.</Card.Meta>
                    <Card.Description>
                      <Header>Call for Pricing</Header>
                    </Card.Description>
                  </Card.Content>
                  <Card.Content extra>{callBtn}</Card.Content>
                </Card>
                <Card centered color="blue" raised>
                  <Image
                    as="a"
                    href="/gallery"
                    src={house}
                    wrapped
                    ui={false}
                    alt="CAM Pressure Washing Lakeland House"
                  />
                  <Card.Content>
                    <Card.Header>House Cleaning</Card.Header>
                    <Card.Meta>Vinyl, Stucco, Brick, etc.</Card.Meta>
                    <Card.Description>
                      <Header>Call for Pricing</Header>
                    </Card.Description>
                  </Card.Content>
                  <Card.Content extra>{callBtn}</Card.Content>
                </Card>
                <Card centered color="blue" raised>
                  <Image
                    as="a"
                    href="/gallery"
                    src={fence}
                    wrapped
                    ui={false}
                    alt="CAM Pressure Washing Lakeland Fence"
                  />
                  <Card.Content>
                    <Card.Header>Fence Cleaning</Card.Header>
                    <Card.Meta>Vinyl, etc.</Card.Meta>
                    <Card.Description>
                      <Header>Call for Pricing</Header>
                    </Card.Description>
                  </Card.Content>
                  <Card.Content extra>{callBtn}</Card.Content>
                </Card>
                <Card centered color="blue" raised>
                  <Image
                    as="a"
                    href="/gallery"
                    src={patio}
                    wrapped
                    ui={false}
                    alt="CAM Pressure Washing Lakeland Patio"
                  />
                  <Card.Content>
                    <Card.Header>Patio Cleaning</Card.Header>
                    <Card.Meta>Concrete, Tile, Wood, ect.</Card.Meta>
                    <Card.Description>
                      <Header>Call for Pricing</Header>
                    </Card.Description>
                  </Card.Content>
                  <Card.Content extra>{callBtn}</Card.Content>
                </Card>
                <Card centered color="blue" raised>
                  <Image
                    as="a"
                    href="/gallery"
                    src={deck}
                    wrapped
                    ui={false}
                    alt="CAM Pressure Washing Lakeland Deck"
                  />
                  <Card.Content>
                    <Card.Header>Deck Cleaning</Card.Header>
                    <Card.Meta>Composite, Concrete, Wood, etc.</Card.Meta>
                    <Card.Description>
                      <Header>Call for Pricing</Header>
                    </Card.Description>
                  </Card.Content>
                  <Card.Content extra>{callBtn}</Card.Content>
                </Card>
                <Card centered color="blue" raised>
                  <Image
                    as="a"
                    href="/gallery"
                    src={dock}
                    wrapped
                    ui={false}
                    alt="CAM Pressure Washing Lakeland Dock"
                  />
                  <Card.Content>
                    <Card.Header>Dock Cleaning</Card.Header>
                    <Card.Meta>Composite, Concrete, Wood, etc.</Card.Meta>
                    <Card.Description>
                      <Header>Call for Pricing</Header>
                    </Card.Description>
                  </Card.Content>
                  <Card.Content extra>{callBtn}</Card.Content>
                </Card>
                <Card centered color="blue" raised>
                  <Image
                    as="a"
                    href="/gallery"
                    src={gutter}
                    wrapped
                    ui={false}
                    alt="CAM Pressure Washing Lakeland Gutter"
                  />
                  <Card.Content>
                    <Card.Header>Gutter Cleaning</Card.Header>
                    <Card.Meta>Leaves, Exterior, etc.</Card.Meta>
                    <Card.Description>
                      <Header>Call for Pricing</Header>
                    </Card.Description>
                  </Card.Content>
                  <Card.Content extra>{callBtn}</Card.Content>
                </Card>
              </Card.Group>
              {/* <Card.Group>
                
              </Card.Group> */}
            </Grid.Row>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

export default Services;