import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
} from "react-router-dom";
import {
  Container,
  Grid,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Icon,
} from "semantic-ui-react";

// import moment from "moment";

import "pure-react-carousel/dist/react-carousel.es.css";
import logo from "./img/CamLogoFinal.svg";
import wave from "./img/wave.svg";

import Homepage from "./pages/homepage.js";
import Services from "./pages/services.js";
import Contact from "./pages/contact.js";
import Gallery from "./pages/gallery.js";
import "./App-v2.css";

import phoneCall from "./convert";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fixed: false,
    };
  }

  // getWidth = () => {
  //   const isSSR = typeof window === "undefined";
  //   return isSSR ? Responsive.onlyTablet.minWidth : window.innerWidth;
  // };

  // getYear = () => {
  //   return moment().format("YYYY");
  // };

  hideFixedMenu = () => this.setState({ fixed: false });
  showFixedMenu = () => this.setState({ fixed: true });

  componentDidMount = () => {};

  render() {
    const { fixed } = this.state;

    return (
      <Router basename={process.env.PUBLIC_URL}>
        <div className="wrapper">
          <Segment textAlign="center" className="App-header" vertical>
            <Image
              src={wave}
              alt="CAM Pressure Washing Wave"
              style={{ width: "100%", position: "absolute" }}
            />
            <br />
            <br />
            <Link to="/">
              <Image
                src={logo}
                alt="CAM Pressure Washing Logo"
                size="huge"
                centered
              />
            </Link>
          </Segment>

          <Menu
            inverted
            pointing={!fixed}
            size="huge"
            widths="3"
            color="blue"
            className="navbar"
            style={{ margin: "0em", borderRadius: "0" }}
          >
            <Menu.Item as="a" href="/services">
              Services
            </Menu.Item>
            <Menu.Item as="a" href="/contact">
              Contact Us
            </Menu.Item>
            <Menu.Item as="a" href="/gallery">
              Gallery
            </Menu.Item>
          </Menu>

          <Container className="pageContainer">
            <Segment className="pageSegment" raised padded>
              <Routes>
                <Route path="/services" element={<Services />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/gallery" element={<Gallery />} />
                <Route path="/" element={<Homepage />} />
              </Routes>
            </Segment>
          </Container>

          <Segment inverted vertical className="footer" color="blue">
            <Container>
              <Grid inverted divided stackable>
                <Grid.Row>
                  <Grid.Column width={4}>
                    <Header inverted as="h3" content="Links" />
                    <List link inverted size="huge">
                      <List.Item as="a" href="/services">
                        Services
                      </List.Item>
                      <List.Item as="a" href="/contact">
                        Contact Us
                      </List.Item>
                      <List.Item as="a" href="/gallery">
                        Gallery
                      </List.Item>
                    </List>
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <Header inverted as="h3" content="Contact Info" />
                    <List link inverted size="huge">
                      <List.Item>CAM Pressure Washing</List.Item>
                      <List.Item>
                        <Icon name="map marker alternate" /> 744 Cedar Knoll Dr
                        N - Lakeland, FL 33809
                      </List.Item>
                      <List.Item
                        as="a"
                        href="mailto:campressurewashing@gmail.com"
                      >
                        <Icon name="mail" /> campressurewashing@gmail.com
                      </List.Item>
                      <List.Item
                        as="a"
                        href="tel:+18634091471"
                        onClick={phoneCall}
                      >
                        <Icon name="phone" /> 863-409-1471
                      </List.Item>
                    </List>
                  </Grid.Column>
                  {/* <Grid.Column width={8}>
                    <Header as='h4' inverted>Copyright</Header>
                    <p>Copyright © 2012 - {this.getYear()} Tablet Gun. All rights reserved.</p>
                  </Grid.Column> */}
                </Grid.Row>
              </Grid>
            </Container>
          </Segment>
        </div>
      </Router>
    );
  }
}

export default App;
