import React, { Component } from 'react';
import {
  Segment,
  Reveal,
  Image,
} from "semantic-ui-react";

class ImageTile extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let content;
    let size = 'medium';
    if (this.props.hide) {
      content = <Reveal as='a' onClick={() => this.props.enlarge()} animated='move' instant>
        <Reveal.Content hidden>
          <Image src={this.props.hide} size={size} alt={'CAM Pressure Washing Lakeland ' + this.props.alt + ' before'} rounded bordered />
        </Reveal.Content>
        <Reveal.Content visible>
          <Image src={this.props.show} size={size} alt={'CAM Pressure Washing Lakeland ' + this.props.alt + ' after'} rounded bordered />
        </Reveal.Content>
      </Reveal>;
    }
    else {
      content = <Image as='a' onClick={() => this.props.enlarge()} src={this.props.show} size={size} alt={'CAM Pressure Washing Lakeland ' + this.props.alt} rounded bordered />;
    }

    return (
      <div>
        <Segment compact style={{display: 'block'}}>
          {content}
        </Segment>
      </div>
    )
  }
}

export default ImageTile;